import React from 'react'
import Link from 'gatsby-link'
import navigateTo from 'gatsby-link'
import Splash from '../components/Splash'
import { Divider} from 'semantic-ui-react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import 'semantic-ui-css/semantic.min.css';
import './style.css'
import WordForm from '../components/WordForm'
import { navigate } from "gatsby"  

class IndexPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          splashVisible: true,
          sketchMount: true
      }
      this.hideSplash = this.hideSplash.bind(this)
      this.destroySplashElement = this.destroySplashElement.bind(this)

    }

    hideSplash() {
        console.log('hi')
        this.setState({
            splashVisible: false,
        }, () => {
            setTimeout(this.destroySplashElement, 2000);
        })
    }

    componentDidMount() {
        console.log(this.props.location.splashStatus)
        if (this.props.location.spashStatus == false) {
            this.setState({sketchMount: false})
        }

    }

    destroySplashElement() {
        navigate('/table-of-contents')
        this.setState({sketchMount: false}, ()=> {
            console.log(this.state)
        })
    }

    render() {
        console.log(this.state.sketchMount)
        return (
            <div className="index-wrapper" >
                      {/* {this.state.splashVisible && <WordForm hideSplash={this.hideSplash}/> } */}
                      <Splash hideSplash={this.hideSplash} visible={this.state.splashVisible} sketchMount={this.state.sketchMount}/>

            </div>
        )
    }
}

export default IndexPage
