export default function Sketch1 (p) {
  console.log("PROCES", process.env.GATSBY_AT_KEY)
let t = 0;

p.setup = function () {
  p.createCanvas(p.windowWidth, p.windowHeight);
  p.noStroke();
  p.fill(225, 200, 240, 100);
}


p.draw = function() {
  p.background(10, 10, 10, 8); // translucent background (creates trails)

  // make a x and y grid of ellipses
  for (let x = 0; x <= p.width; x = x + 10) {
    for (let y = 0; y <= p.height; y = y + 30) {
      // starting point of each circle depends on mouse position
      let xAngle = p.map(p.mouseX + 5, 0, p.width, -2 * p.PI, 4 * p.PI, true);
      let yAngle = p.map(p.mouseY, 0, p.height, -4 * p.PI, 4 * p.PI, true);
      // and also varies based on the particle's locppation
      let angle = xAngle * (x / p.width) + yAngle * (y / p.height);

      // each particle moves in a circle
      let myX = x + 20 * p.cos(p.random(3) * p.PI * t + angle);
      let myY = y + 20 * p.sin(p.random(2) * p.PI * t + angle);

      p.ellipse(myX, myY, p.random(3)); // draw particle
    }

  }
  t = t + 0.01; // update time
  if (p.kill === true) {
      console.log('holy fuck')
      p.remove()
  }
}

p.windowResized = function() {
  p.resizeCanvas(p.windowWidth, p.windowHeight)
}


}
