export default (p) => {
	let x = 0;
	let y = 0;
	let spacing = 10;
	let r, g, b;

	p.setup = function() {
		p.createCanvas(p.windowWidth/2, p.windowHeight);
		p.background(0);
		r = p.random(255);
		g = p.random(255);
		b = p.random(255);
	};

	p.draw = function() {
		p.stroke(r,g,b);

		if (p.random(1) < 0.5) {
			p.line( x, y, x + spacing, y + spacing);
		}
		else {
			p.line(x, y + spacing, x + spacing, y);
		}

		x = x + spacing;

		if (x > p.width) {
			x = 0;
			y = y + spacing;
		}

		if ( y > p.windowHeight) {
			x = 0;
			y = 0;

			p.clear();
			p.background(0);
		}

		r = p.random(255);
		g = p.random(255);
		b = p.random(255);
	};

	p.windowResized = () => {
		p.resizeCanvas(p.windowWidth/2, p.windowHeight);
		p.background(0);

		x = 0;
		y = 0;
		p.redraw();
	};
}
