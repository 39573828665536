import React from 'react'
import Link from 'gatsby-link'
import 'semantic-ui-css/semantic.min.css';
import P5Component from '../P5Component'
import './style.css'
import { sketchData } from '../sketchData'
import skull from '../../assets/images/skull.png'
import sketch1 from "../../sketches/01.js"
import navigateTo from 'gatsby-link'

class Splash extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          form: true
      }
  }

  replaceLetter(x) {
      const matchNum = Math.floor(Math.random() * 4) + 1;
      let newLetter = ""
      if (matchNum === 1) {
          newLetter = "0"
      }
      if (matchNum === 2) {
          newLetter = "1"
      }

      if (matchNum === 3) {
          newLetter = "*"
      }

      if (matchNum === 4) {
          newLetter = "*"
      }
  }

  closeForm() {
      this.setState({
          form: false
      })
  }


  render() {
      
      // if (this.props.sketchMount) {
      return (
        <div className={this.props.visible ? 'fadeIn splash-wrapper':'fadeOut splash-wrapper'} key={this.props.key}>    
             <Link to ="/table-of-contents">
            <div className="p5-container">
            <P5Component sketch = {sketch1} sketchMount={this.props.sketchMount}/>
            </div>
            <div className="splash-content">
                <div className="huge-logo">
                <img className="splash-skull" src={skull}/>
                        <div className="huge-logo-text">
                            The <span className="star-font">*</span> Future <span className="star-font">*</span> of <span className="star-font">*</span> Death <span className="star-font">*</span> in <span className="star-font">*</span> America
                        </div>
                </div>
                </div>
                {/* <div className="hide-splash" onClick={this.props.hideSplash}>Enter</div> */}
                </Link>
            </div>
    )
// }
    // else return null
  }

}

export default Splash
