import s00 from "../sketches/00"
import s01 from "../sketches/01"
import s02 from "../sketches/02"
import s03 from "../sketches/03"

export const sketchData = [
	{
		id: `00`,
		title: `First One`,
		file: s00,
		description: `Oh hey the first one, a test`
	},
	{
		id: `01`,
		title: `Second One`,
		file: s01,
		description: `I swear this was different than the first one, but I guess not anymore`
	},
	{
		id: `02`,
		title: `Recamán's`,
		file: s02,
		description: `Based on a Shiffman Tutorial, who was inspired by the Recaman sequence from a numberphile video`
	},
	{
		id: `03`,
		title: `10 Print`,
		file: s03,
		description: `The famous 10 print sequence from Commodore 64s, ready the Casey Reas book and saw Shiffman's video. But this one has colors!`
	}
]
